import React from 'react'

const Harvey = function({className, ...rest}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="20" width="20" {...rest}>
      <circle cx="10" cy="10" r="9.4" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
    </svg>
  )
}

export default Harvey
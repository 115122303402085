import React, { Fragment } from 'react'
import { useClasses } from '../lib/ReactUtils'
import Popup from './Popup'
import '../styles/Tables'
import Info from '../svg/Info'
import Harvey0 from '../svg/Harvey0'
import Harvey1 from '../svg/Harvey1'
import Harvey2 from '../svg/Harvey2'
import Harvey3 from '../svg/Harvey3'
import Harvey4 from '../svg/Harvey4'


export default function Comparison({headers, data}) {
  const root = useClasses()
  const columns = headers.length+1
  const colors = headers.map((h) => h.color || 'blue')
  return (
    <table ref={root}>
      <thead>
        <tr>
          <th></th>
          {headers.map((h, index) => 
            <th key={index} className={'text-'+colors[index]+'-800 text-lg pb-3 mx-auto text-center'}>
              <span className="hidden md:block">{h.label}</span>
              <img className="md:hidden w-6 h-6 mx-2" src={h.image} alt={h.label}/>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((s, index) =>
          <Fragment key={index}>
            { s.title ?
              <>
                <tr className='h-6'/>
                <tr>
                  <td colSpan={columns} className='bg-primary-dark font-semibold text-white text-lg px-2 py-2 rounded'>
                    { s.title }
                    { s.footnote ? <span className='text-xs text-gray-200 font-light pl-2'>[{s.footnote}]</span>: '' }
                  </td>
                </tr>
                <tr className='h-2'/>
                <tr/>
              </> : null
            }
            {s.rows.map((r,index) => (
              <tr key={index} className='row'>
                <td className='cell'>
                  { r.label }
                  { r.popup ?
                    <Info
                      onClick={(e) => Popup.show({context: e.currentTarget, text: r.popup, className: 'text-sm font-light text-white p-3 bg-gray-700 max-w-sm opacity-90'})}
                      className="h-6 w-6 inline text-gray-600 hover:text-black duration-200 transition ml-2 cursor-pointer"
                    /> : null
                  }
                </td>
                {r.data.map((score,index) =>
                  <td key={index} className='text-center'>
                    { score == 0 ? <Harvey0 className={'inline text-'+colors[index]+'-700'}/> : null }
                    { score == 1 ? <Harvey1 className={'inline text-'+colors[index]+'-700'}/> : null }
                    { score == 2 ? <Harvey2 className={'inline text-'+colors[index]+'-700'}/> : null }
                    { score == 3 ? <Harvey3 className={'inline text-'+colors[index]+'-700'}/> : null }
                    { score == 4 ? <Harvey4 className={'inline text-'+colors[index]+'-700'}/> : null }
                  </td>
                )}
              </tr>
            ))}
            {/* for even-odd sharing to work */}
            {s.rows.length % 2 ? <tr/> : null}
          </Fragment>
        )}
      </tbody>
    </table>
  )
}

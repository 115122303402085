import React from 'react'

const Harvey = function({className, ...rest}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="20" width="20" {...rest}>
      <circle cx="10" cy="10" r="9.4" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
      <path fill="currentColor" d="M9.705.56v18.85c5.382-.112 9.472-3.122 9.62-9.515-.01-5.126-3.2-9.658-9.62-9.336z" strokeWidth=".41"/>
    </svg>
  )
}

export default Harvey
import React from 'react'
import { useClasses } from '../lib/ReactUtils'

export default function DataTable({headers, data}) {
  const root = useClasses()
  return (
    <table ref={root}>
      <thead className='border-b-3 border-gray-600'>
        <tr className='h-10 align-top'>
          {headers.map((h, index) => 
            <th key={index} className={'text-lg text-left ' + (h.className || '')}>
              {h.label}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) =>
          <tr key={index} className='row'>
            <td className={'cell ' + (row[0].className || '')}>{row[0].label}</td>
            <td className={'cell ' + (row[1].className || '')}>{row[1].label}</td>
            <td className={'cell ' + (row[2].className || '')}>{row[2].label}</td>
            <td className={'cell ' + (row[3].className || '')}>
              <div className='flex flex-col gap-y-1'>
                {row[3].map((row, index) =>
                  <div key={index} className={row.className}>{row.label}</div>
                )}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}


import React from 'react'

const Harvey = function({className, ...rest}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="20" width="20" {...rest}>
      <circle cx="10" cy="10" r="9.4" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
      <path fill="currentColor" d="M9.706.792s2.973-.09 6.83 2.73c3.022 3.486 2.73 6.772 2.73 6.772h-9.56z" strokeWidth=".41"/>
    </svg>
  )
}

export default Harvey
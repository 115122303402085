import React from 'react'

const Harvey = function({className, ...rest}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="20" width="20" {...rest}>
      <circle cy="10" cx="10" r="9.4" fill="currentColor"/>
    </svg>
  )
}

export default Harvey
import React from 'react'
import { useClasses, useQueryParam } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Comparison from '../components/Comparison'
import DataTable from '../components/DataTable'
import CTA from '../components/CTA'
import { Link } from 'gatsby'
import '../styles/ToggleButton'
import OO from '../images/comparison/oneoffice.svg'
import MS from '../images/comparison/microsoft.svg'
import GOOGLE from '../images/comparison/google.svg'

const headers1 = [
  {color:'orange', label:'OneOffice', image: OO},
  {color:'blue', label:'MS 365', image: MS},
  {color:'purple', label:'G-Suite', image: GOOGLE}
]

// https://nextcloud.com/compare/
const data1 = [
  {
    title: 'Features & Benefits',
    rows: [
      {label: 'Cost', data: [4,1,1], popup:'Please visit our pricing page for more information'},
      {label: 'Single location', data: [4,0,0], popup:'A key feature of OneOffice is that it\'s all there, in one place, right within your browser or App'},
      {label: 'Quick page load', data: [4,2,3], popup:'OneOffice is optimized and cashes its code in your browser'},
      {label: 'Ease of use', data: [4,2,3]},
      {label: 'File drop', data: [4,0,4]},
      {label: 'Word, Excel, PowerPoint support', data: [4,4,2], popup:'The internal format of OneOffice is Word, Excel and Powerpoint. Google Docs conversion does not include advanced Office functions'},
      {label: 'View / Export PDF', data: [4,4,4]},
      {label: 'Timesheet', data: [4,0,0]},
      {label: 'Tasks & basic project management', data: [4,0,0], popup:'Microsoft also sells advanced project management tools, meant for professional project managers'},
      {label: 'Calendar & Contacts', data: [4,4,4]},
      {label: 'Video conference', data: [4,3,2], popup:'OneOffice has built-in whiteboard, breakout rooms and collaborative text editing. MS Teams only includes whiteboard. Google Meet does not include advanced features' },
      {label: 'Video conference recording', data:[4,4,4], popup:'MS Teams does not record whiteboard'}
    ]
  },
  {
    title: 'Security & Control',
    rows: [
      {label: 'On-premises hosting', data:[4,2,0], popup: 'You cannot host MS 365 on-premises, Google is interested in your data so has to be on their servers only.'},
      {label: 'Hybrid hosting', data: [4,2,0], popup: 'MS 365 only allows partial hosting'},
      {label: 'Audit trail', data: [4,1,1], popup: 'History of user activities'},
      {label: 'Block download', data: [4,2,2], popup: 'G-Suite and MS-365 use client-side editors, users can copy your content from their browser                                                '},
      {label: 'Server-side encryption', data: [4,4,4]},
      {label: 'End-to-End encryption', data: [4,0,0], popup: 'E2E uses your private key to encrypt critical traffic'},
      {label: 'File access control', data: [4,3,2], popup: 'OneOffice allows multiple sharing scenarios concurrently with finer controls'},
      {label: 'Email Spam filtering', data: [3,3,4], popup: 'Google remains the leader in Spam filtering due to the volume of emails they process. However, your privacy is compromised.'},
    ]
  },
  {
    title: 'Administration',
    rows: [
      {label: 'Automated flows', data:[4,4,0]},
      {label: 'Workspaces', data: [4,0,0]},
      {label: 'Block external sharing', data:[4,0,0]},
      {label: 'Timed external sharing', data:[4,0,0]},
      {label: 'Email server', data: [4,4,4], popup: 'OneOffice also supports third-party email service'},
    ]
  },
  {
    title: 'Authentication',
    rows: [
      {label: 'Suspicious logins detection', data:[4,4,4]},
      {label: 'Brute-force detection', data: [4,4,4]},
      {label: 'Two factor Authentication (2FA)', data: [4,4,4]},
      {label: 'LDAP / ActiveDirectory', data: [4,4,2]},
      {label: 'Kereberos', data: [4,4,0], popup: 'Microsoft Kereberos authentication requires ActiveDirectory with a Domain enviroment'},
      {label: 'Custom OAuth', data: [4,0,0]}
    ]
  },
  {
    title: 'Storage Locations',
    footnote: 'Including on-premises hosting',
    rows: [
      {label: 'Local (NFS / FS)', data:[4,3,0], popup: 'Opening a file in MS 365 will require upload each time. Note: We assume OneOffice is hosted on-premises'},
      {label: 'Network / Samba', data: [4,3,0], popup: 'Opening a file in MS 365 will require upload each time. Note: We assume OneOffice is hosted on-premises'},
      {label: 'Object (S3 / SWIFT)', data: [4,0,0], popup: 'Storing on Amazon or other PaaS'},
      {label: 'Sharepoint', data: [4,3,0], popup: 'Opening a file in MS 365 will require upload each time. Note: We assume OneOffice is hosted on-premises'}
    ]
  },
]

const headers2 = [
  {label:'MS 365', className: 'text-left'},
  {label:'OneOffice'},
  {label:'MS Plan Description'},
  {label:'MS 365 vs. OneOffice'},
]

const data2 = [
  [
    {label:'Apps for business - $8.25', className:'font-bold'},
    {label:'OneOffice Productivity - $9.50'},
    {label:'Desktop plan with online storage', className:'font-bold text-primary-dark'},
    [
      {label:'No Email', className:'text-red-600 font-bold'},
      {label:'Storage', className:'font-bold'},
      {label:'Desktop Apps', className:'font-bold'}, 
      {label:'No Video Calls', className:'text-red-600 font-bold'},
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-800 font-bold uppercase'}
    ]
  ],
  [
    {label:'Business Basic - $6.00', className:'font-bold'},
    {label:'OneOffice Productivity - $9.50'},
    {label:'Very basic starter plan with no Apps', className:'font-bold text-primary-dark'},
    [
      {label:'Storage & Email', className:'font-bold'},
      {label:'No Desktop Apps', className:'text-red-600 font-bold'}, 
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No Backups', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-700 font-bold font-bold uppercase'}
    ]
  ],
  [
    {label:'Business Standard - $12.50', className:'font-bold'},
    {label:'OneOffice Productivity - $9.50'},
    {label:'Good plan with weak data protection', className:'font-bold text-primary-dark'},
    [
      {label:'Storage & Email', className:'font-bold'},
      {label:'Desktop Apps', className:'font-bold'}, 
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No Backups', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-700 font-bold font-bold uppercase'}
    ]
  ],
  // https://www.microsoft.com/en-us/microsoft-365/enterprise/compare-office-365-plans
  [
    {label:'Business Premium - $22.00', className:'font-bold'},
    {label:'OneOffice Productivity - $9.50'},
    {label:'Good plan with data protection', className:'font-bold text-primary-dark'},
    [
      {label:'Storage & Email', className:'font-bold'},
      {label:'Desktop Apps', className:'font-bold'}, 
      {label:'Better Security', className:'font-bold'},
      {label:'No Backups', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-700 font-bold font-bold uppercase'}
    ]
  ],
  [
    {label:'Office 365 E3 - $23.00', className:'font-bold'},
    {label:'OneOffice Productivity - $9.50'},
    {label:'Solid plan with data protection', className:'font-bold text-primary-dark'},
    [
      {label:'Storage & Email', className:'font-bold'},
      {label:'Desktop Apps', className:'font-bold'}, 
      {label:'Strong Security', className:'font-bold'},
      {label:'No Backups', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-700 font-bold font-bold uppercase'}
    ],
  ],
  // https://www.microsoft.com/en-us/microsoft-365/exchange/compare-microsoft-exchange-online-plans
  [
    {label:'Exchange Online (Plan 1) - $4.00', className:'font-bold'},
    {label:'OneOffice Email - $4.00'},
    {label:'Email Only', className:'font-bold text-primary-dark'},
    [
      {label:'Email Only', className:'font-bold'},
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-800 font-bold uppercase'}
    ]
  ],
  [
    {label:'Exchange Online Kiosk - $2.00', className:'font-bold'},
    {label:'OneOffice Email - $4.00'},
    {label:'Email Only [2GB]', className:'font-bold text-primary-dark'},
    [
      {label:'2G Email Only', className:'text-red-600 font-bold'},
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-800 font-bold uppercase'}
    ]
  ],
  // https://www.microsoft.com/en-us/microsoft-365/onedrive/compare-onedrive-plans?activetab=tab:primaryr2
  [
    {label:'OneDrive for Business (Plan 1) - $5.00', className:'font-bold'},
    {label:'OneOffice Drive - $4.00'},
    {label:'Pure Cloud Storage [Cannot edit docs]', className:'font-bold text-primary-dark'},
    [
      {label:'Storage', className:'font-bold'},
      {label:'No Web or Desktop Apps', className:'text-red-600 font-bold'}, 
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-800 font-bold uppercase'}
    ]
  ],
  // https://www.microsoft.com/en-us/microsoft-365/project/compare-microsoft-project-management-software?activetab=tabs:primaryr1
  [
    {label:'Project Plan 3 - $30.00', className:'font-bold'},
    {label:'OneOffice Project - $9.50 (coming soon)'},
    {label:'Pure MS Project Online', className:'font-bold text-primary-dark'},
    [
      {label:'3GB Storage', className:'text-red-600 font-bold'},
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-800 font-bold uppercase'}
    ]
  ],
  // https://www.microsoft.com/en-us/microsoft-365/visio/visio-plan-2?activetab=pivot:overviewtab
  [
    {label:'Visio Plan 2 - $15.00', className:'font-bold'},
    {label:'OneOffice Productivity - $9.50'},
    {label:'Pure MS Visio Online', className:'font-bold text-primary-dark'},
    [
      {label:'No Email', className:'text-red-600 font-bold'},
      {label:'3GB Storage', className:'text-red-600 font-bold'},
      {label:'Only Visio Desktop', className:'text-red-600 font-bold'}, 
      {label:'No Video Calls', className:'text-red-600 font-bold'},
      {label:'Basic Security', className:'text-red-600 font-bold'},
      {label:'No data loss prevention', className:'text-red-800 font-bold uppercase'}
    ]
  ],

]


export default function ComparePage() {
  const root = useClasses()
  const [mode, setMode] = useQueryParam('mode', 'all')
  return (
    <Layout short>
      <SEO title='Compare'/>
      <main className="main" ref={root}>
        <span className="heading-separator"/>
        <h1 className="title">Compare</h1>
        <h2 className="subtitle">Built from the ground up after years of legacy solutions</h2>
        <p className='description mb-20'>
          Here is an honest comparison of the most important features for SME's and most large organizations. 
          We focus on the features that most companies need, don't hesitate to <Link to='/contact'>reach out</Link> if you have specific questions.
          You can also consult our <Link to='/features'>Features</Link> and <Link to='/faqs'>FAQs</Link> for more details.
        </p>

        <div className='bg-gradient-to-r from-sky-600 to-indigo-400 shadow py-6 md:py-15 my-15 rounded-xl'>
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-xl md:text-2xl xl:text-4xl font-bold text-white mb-5 md:mb-7">Show plans from ...</h2>
            <div className="toggle-button-group" role="group">
              <button
                type="button"
                onClick={() => setMode('all')}
                className={"toggle-button" + (mode === 'all' ? ' active' : '')}
              >
                <img className='w-8 h-8 inline md:-mt-1' src={OO} alt='OneOffice'/>
                <span className='hidden md:inline md:pl-2'>All</span>
              </button>  
              <button
                type="button"
                onClick={() => setMode('ms')}
                className={"toggle-button" + (mode === 'ms' ? ' active' : '')}
              >
                <img className='w-8 h-8 inline md:-mt-1' src={MS} alt='Microsoft'/>
                <span className='hidden md:inline md:pl-2'>MS 365</span>
              </button>           
            </div>           
          </div>
        </div>

        { mode === 'all' && <Comparison headers={headers1} data={data1}/> }
        { mode === 'ms' && <DataTable headers={headers2} data={data2}/> }
        <CTA variant={1}/>
      </main>
    </Layout>
  )
}


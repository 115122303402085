import React from 'react'

const Harvey = function({className, ...rest}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="20" width="20" {...rest}>
      <circle cx="10" cy="10" r="9.4" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
      <path fill="currentColor" d="M9.75.962v9.244H.565S1 13.758 3.227 16.81c2.617 2.01 4.566 2.533 6.663 2.6 6.172-.323 9.36-4.133 9.307-9.313C19.244 3.995 16.255.766 9.75.962z" strokeWidth=".41"/>
    </svg>
  )
}

export default Harvey